import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

export default function ContactForm() {
  const [mensajeEnviado, setmensajeEnviado] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'contact',
        ...data,
      }),
    })
      .then(() => {
        setmensajeEnviado(true);
      })
      .catch((error) => console.error(error));
  };

  return (
    <form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit(onSubmit)}
    >
      <input type="hidden" name="form-name" value="contact" />

      <label htmlFor="contactName">
        Nombre <span className="required">*</span>
      </label>
      <input
        type="text"
        placeholder="Edwin Noriega"
        {...register('contactName', { required: true, maxLength: 80 })}
      />
      <label htmlFor="contactPhone">
        Teléfono <span className="required">*</span>
      </label>
      <input
        type="tel"
        placeholder="89605494"
        {...register('contactPhone', { required: true, maxLength: 12 })}
      />
      <label htmlFor="contactMessage">
        Mensaje <span className="required">*</span>
      </label>
      <textarea {...register('contactMessage', { required: true })} />

      <div>
        {Object.keys(errors).length > 0 && (
          <div id="message-warning">
            <p>Completa los campos obligatorios</p>
          </div>
        )}

        {mensajeEnviado && (
          <div id="message-success">
            <i className="fa fa-check"></i>Gracias por tu mensaje, pronto me
            pondré en contacto contigo
            <br />
          </div>
        )}
        <button className="submit">Enviar</button>
        <span id="image-loader">
          <img alt="Loader" src="images/loader.gif" />
        </span>
      </div>
    </form>
  );
}
