import React, { Component } from 'react';
import Slide from 'react-reveal';

class Resume extends Component {
  getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    if (!this.props.data) return null;

    const skillmessage = this.props.data.skillmessage;
    const education = this.props.data.education.map(function (education) {
      return (
        <div key={education.school}>
          <h3>{education.school}</h3>
          <p className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          <p>{education.description}</p>
        </div>
      );
    });

    const work = this.props.data.work.map(function (work) {
      return (
        <div key={work.company}>
          <h3>{work.company}</h3>
          <p className="info">
            {work.title}
            <span>&bull;</span> <em className="date">{work.years}</em>
          </p>
          <p>{work.description}</p>
        </div>
      );
    });

    // const skills = this.props.data.skills.map((skills) => {
    //   const backgroundColor = this.getRandomColor();
    //   const className = 'bar-expand ' + skills.name.toLowerCase();
    //   const width = skills.level;

    //   return (
    //     <li key={skills.name}>
    //       <span style={{ width, backgroundColor }} className={className}></span>
    //       <em>{skills.name}</em>
    //     </li>
    //   );
    // });

    const skills = this.props.data.skills.map(function (skills) {
      let projectImage = 'images/tech/' + skills.image;
      return (
        <div key={skills.name} className="columns feature-item total-skills">
          <img className="skill" alt={skills.name} src={projectImage} />
          {/* <h5>{skills.name}</h5> */}
          {/* <p>{skills.description}</p> */}
        </div>
      );
    });

    return (
      <section id="resume">
        <Slide left duration={1300}>
          <div className="row education">
            <div className="three columns header-col">
              <h1>
                <span>Educación</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <div className="row item">
                <div className="twelve columns">{education}</div>
              </div>
            </div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          <div className="row work">
            <div className="three columns header-col">
              <h1>
                <span>Experiencia</span>
              </h1>
            </div>

            <div className="nine columns main-col">{work}</div>
          </div>
        </Slide>

        <Slide left duration={1300}>
          {/* <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Habilidades</span>
              </h1>
            </div>

            <div className="nine columns main-col">
              <p>{skillmessage}</p>
              <img
                alt="HTML5"
                src="https://img.shields.io/badge/html5-%23E34F26.svg?style=for-the-badge&logo=html5&logoColor=white"
              />

              <div className="bars">
                <ul className="skills">{skills}</ul>
              </div>
            </div>
          </div> */}
          <div className="row skill">
            <div className="three columns header-col">
              <h1>
                <span>Tecnologías</span>
              </h1>
            </div>

            <div>
              <div className="nine columns main-col">
                <p className="lead center">{skillmessage}</p>
              </div>
              <ul className="bgrid-quarters s-bgrid-thirds cf">{skills}</ul>
            </div>
          </div>
        </Slide>
      </section>
    );
  }
}

export default Resume;

// "skills": [
//   {
//     "name": "ReactJs",
//     "level": "80%"
//   },
//   {
//     "name": "HTML5",
//     "level": "95%"
//   },
//   {
//     "name": "CSS3",
//     "level": "95%"
//   },
//   {
//     "name": "Bootstrap",
//     "level": "90%"
//   },
//   {
//     "name": "Node.js",
//     "level": "80%"
//   },
//   {
//     "name": "Mysql",
//     "level": "80%"
//   },
//   {
//     "name": "C#",
//     "level": "70%"
//   },
//   {
//     "name": "AWS",
//     "level": "70%"
//   }
// ]
// },
