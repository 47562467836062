import React from 'react';
import { Fade, Slide } from 'react-reveal';
import ContactForm from './ContactForm';

const Contact = (props) => {
  if (!props.data) return null;

  const name = props.data.name;
  const { street, city, state: estado, zip } = props.data.address;
  const phone = props.data.phone;
  const message = props.data.contactmessage;

  return (
    <section id="contact">
      <Fade bottom duration={1000}>
        <div className="row section-head">
          <div className="two columns header-col">
            <h1>
              <span>Ponte en contacto.</span>
            </h1>
          </div>

          <div className="ten columns">
            <p className="lead">{message}</p>
          </div>
        </div>
      </Fade>

      <div className="row">
        <Slide left duration={1000}>
          <div className="eight columns">
            <ContactForm />
          </div>
        </Slide>

        <Slide right duration={1000}>
          <aside className="four columns footer-widgets">
            <div className="widget widget_contact">
              <h4>Dirección y teléfono</h4>
              <p className="address">
                {name}
                <br />
                {street} <br />
                {city}, {estado} {zip}
                <br />
                <span>
                  <a href="https://wa.me/50489605494">{phone}</a>
                </span>
              </p>
            </div>
          </aside>
        </Slide>
      </div>
    </section>
  );
};

export default Contact;
